import { Flex, HStack, useDisclosure, Text } from '@chakra-ui/react';

import { H2 } from './Blocks/H2';
import Image from 'next/image';
import TicketsImage from '../../public/images/home/racino_tickets.png';
import { Title } from './Blocks/Title';
import { RTicketsBundleModal } from 'components';
import { IconTicket } from 'icons';
import { useGetTicketsAvailable } from 'hooks';
import { useRouter } from 'next/router';

export const TicketsPanel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: tickets } = useGetTicketsAvailable();
  const router = useRouter();

  return (
    <Flex
      w="100%"
      h="100%"
      bg="rgba(0, 0, 0, 0.4)"
      onClick={() => router.push('/marketplace/tickets')}
      role="group"
      cursor="pointer"
    >
      <Flex
        w="100%"
        h="100%"
        position="relative"
        bgGradient="linear(to-t, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%)"
        _hover={{
          bgGradient:
            'linear(to-t, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)',
        }}
      >
        {/* HEADER */}
        <Flex
          position="absolute"
          top={{ base: 4, md: 4 }}
          left={{ base: 4, md: 4 }}
        >
          <Title maxW="340px">Ticket Summary</Title>
        </Flex>

        {/* CONTENT */}
        <Flex w="100%" h="100%" position="relative">
          <Image
            src={TicketsImage}
            alt="Ticket Summary"
            style={{ objectFit: 'cover', opacity: 0.1 }}
            fill
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </Flex>

        <HStack
          gap={2}
          position="absolute"
          bottom={0}
          left={0}
          top={0}
          right={0}
          m="auto"
          justifyContent="center"
          alignItems="center"
          h="fit-content"
        >
          <IconTicket width={50} height={50} />
          <Text
            fontFamily="body"
            fontSize={'70px'}
            color="bloodMoon.100"
            fontWeight="bold"
          >
            {tickets?.ticketsAvailable || 0}
          </Text>
        </HStack>

        {/* FOOTER */}
        <Flex
          position="absolute"
          bottom={{ base: 4, md: 4 }}
          left={{ base: 4, md: 4 }}
        >
          <H2 maxW="340px" size="xs">
            Buy tickets
          </H2>
        </Flex>
        <BottomBarLine />
        <RTicketsBundleModal isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Flex>
  );
};

export const BottomBarLine = () => {
  return (
    <Flex
      w="calc(100% - 8px)"
      right={0}
      left={0}
      m="auto"
      h="6px"
      borderBottom="2px solid"
      borderX="2px solid"
      borderColor="whiteAlpha.300"
      position="absolute"
      bottom={1}
      transition="all ease-in-out 0.3s"
      _groupHover={{
        borderColor: 'whiteAlpha.600',
      }}
    />
  );
};
